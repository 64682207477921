/* eslint padded-blocks: ["error", "never"] */
import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store.js'

Vue.use(Router)

const router = new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'ROOTPATH',
      component: () => import('@/views/pages.user/Index'),
      children: [
        // Only SIer
        {
          name: 'TenantList',
          path: '/tenant-list',
          component: () => import('@/views/pages.user/TenantList'),
        },
        {
          name: 'SierOperationList',
          path: '/sier-operation-log',
          component: () => import('@/views/pages.common/OperationLog'),
        },
        {
          name: 'SierMailGroup',
          path: '/sier-mail-group',
          component: () => import('@/views/pages.common/MailGroups/MailGroup'),
          props: true,
        },
        {
          name: 'SierMailGroupAddEdit',
          path: '/sier-mail-group/add-edit',
          component: () => import('@/views/pages.common/MailGroups/MailGroupAddEdit'),
          props: true,
        },
        // SIer and Tenant
        {
          name: 'DeviceList',
          path: '/management/device-list',
          component: () => import('@/views/pages.user/Management/DeviceList'),
        },
        {
          name: 'Inventory',
          path: '/management/inventory/:routerid',
          component: () => import('@/views/pages.user/Management/Inventory'),
          props: true,
        },
        {
          name: 'InterfaceStatistics',
          path: '/management/inventory/:routerid/interface/statistics/:ifname',
          component: () => import('@/views/pages.user/Management/InventoryInfo/InterfaceStatistics'),
        },
        {
          name: 'LboStatistics',
          path: '/management/inventory/:routerid/lbo/statistics',
          component: () => import('@/views/pages.user/Management/InventoryInfo/LboStatistics'),
          props: true,
        },
        {
          name: 'sflowStatistics',
          path: '/management/inventory/:routerid/sflow/statistics',
          component: () => import('@/views/pages.user/Management/InventoryInfo/SflowStatistics'),
          props: true,
        },
        {
          name: 'FirmupDetail',
          path: '/management/inventory/:routerid/firmup/:firmupid',
          component: () => import('@/views/pages.user/Management/InventoryInfo/FirmupDetail'),
        },
        {
          name: 'ConfigDetail',
          path: '/management/inventory/:routerid/config/:configid',
          component: () => import('@/views/pages.user/Management/InventoryInfo/ConfigDetail'),
        },
        {
          name: 'ConfigDiff',
          path: '/management/inventory/:routerid/config-diff',
          component: () => import('@/views/pages.user/Management/InventoryInfo/ConfigDiff'),
        },
        {
          name: 'FirmupTaskList',
          path: '/support/firmware-update',
          component: () => import('@/views/pages.user/Support/Firmup/FirmupTaskList'),
          props: true,
        },
        {
          name: 'FirmupTaskRsv',
          path: '/support/firmware-update/reservation',
          component: () => import('@/views/pages.user/Support/Firmup/FirmupTaskRsv'),
        },
        {
          name: 'FirmupTask',
          path: '/support/firmware-update/detail/:taskid',
          component: () => import('@/views/pages.user/Support/Firmup/FirmupTask'),
        },
        {
          name: 'ConfigIndex',
          path: '/support/config-change',
          component: () => import('@/views/pages.user/Support/ConfigChange/ConfigIndex'),
          props: true,
        },
        {
          name: 'ConfigTask',
          path: '/support/config-change/detail/:taskid',
          component: () => import('@/views/pages.user/Support/ConfigChange/ConfigTask'),
        },
        {
          name: 'ConfigTemplateManager',
          path: '/support/config-change/template/:templateid',
          component: () => import('@/views/pages.user/Support/ConfigChange/ConfigTemplateManager'),
        },
        {
          name: 'ConfigTaskRsv',
          path: '/support/config-change/reservation',
          component: () => import('@/views/pages.user/Support/ConfigChange/ConfigTaskRsvIndex'),
          props: true,
        },
        {
          name: 'ExecCli',
          path: '/support/exec-cli',
          component: () => import('@/views/pages.user/Support/ExecCli/ExecCliIndex'),
          props: true,
        },
        {
          name: 'LboIndex',
          path: '/network/lbo',
          component: () => import('@/views/pages.user/Network/Lbo/LboIndex'),
          props: true,
        },
        {
          name: 'LboProfileManager',
          path: '/network/lbo/profile/:profileid',
          component: () => import('@/views/pages.user/Network/Lbo/LboProfileManager'),
        },
        {
          name: 'LboPolicyManager',
          path: '/network/lbo/policy/:policyid',
          component: () => import('@/views/pages.user/Network/Lbo/LboPolicyManager'),
        },
        {
          name: 'DdnsIndex',
          path: '/network/ddns',
          component: () => import('@/views/pages.user/Network/Ddns/DdnsIndex'),
          props: true,
        },
        {
          name: 'OperationList',
          path: '/operation-log',
          component: () => import('@/views/pages.common/OperationLog'),
        },
        {
          name: 'TenantPassword',
          path: '/settings/tenant-password',
          component: () => import('@/views/pages.user/Settings/TenantPassword'),
          props: true,
        },
        {
          name: 'DdnsPassword',
          path: '/settings/ddns-password',
          component: () => import('@/views/pages.user/Settings/DdnsPassword'),
          props: true,
        },
        {
          name: 'TenantUserTimeout',
          path: '/settings/tenant-user-timeout',
          component: () => import('@/views/pages.user/Settings/TenantUserTimeout'),
          props: true,
        },
        {
          name: 'IpPolicy',
          path: '/settings/tenant-ip-policy',
          component: () => import('@/views/pages.user/Settings/TenantIpPolicy'),
          props: true,
        },
        {
          name: 'TenantMonitor',
          path: '/settings/tenant-monitor',
          component: () => import('@/views/pages.user/Settings/TenantMonitor'),
          props: true,
        },
        {
          name: 'TenantMailGroup',
          path: '/settings/tenant-mail-group',
          component: () => import('@/views/pages.common/MailGroups/MailGroup'),
          props: true,
        },
        {
          name: 'TenantMailGroupAddEdit',
          path: '/settings/tenant-mail-group/add-edit',
          component: () => import('@/views/pages.common/MailGroups/MailGroupAddEdit'),
          props: true,
        },
        {
          name: 'Login',
          path: '/login',
          component: () => import('@/views/pages.user/Login'),
          props: true,
        },
        {
          name: 'ResetPassword',
          path: '/reset-password',
          component: () => import('@/views/pages.user/ResetPassword'),
          props: true,
        },
        // Transition to the main page
        {
          name: 'GoToMainPage',
          path: '/go-to-main',
        },
        {
          name: '404',
          path: '*',
          component: () => import('@/views/pages.common/NotFound'),
        },
      ],
    },
  ],
})

const validateRouteForSier = function (to) {
  switch (to) {
    case 'GoToMainPage':
      return true
    case 'ResetPassword':
      return true
  }
  return false
}

const validateRouteForOperator = function (to) {
  switch (to) {
    case 'TenantList':
      return true
    case 'SierOperationList':
      return true
    case 'SierMailGroup':
      return true
    case 'SierMailGroupAddEdit':
      return true
    case 'GoToMainPage':
      return true
    case 'ResetPassword':
      return true
  }
  return false
}

const validateRouteForGuest = function (to) {
  switch (to) {
    case 'TenantList':
    case 'SierOperationList':
    case 'GoToMainPage':
    case 'FirmupTaskRsv':
    case 'ConfigTaskRsv':
    case 'ExecCli':
    case 'TenantPassword':
    case 'DdnsPassword':
    case 'TenantUserTimeout':
    case 'IpPolicy':
    case 'TenantMonitor':
    case 'ResetPassword':
      return true
  }
  return false
}

const validateRouteForZtpOnly = function (to) {
  switch (store.state.priviledge) {
    // guest
    case 1:
      switch (to) {
        case 'TenantList':
        case 'SierOperationList':
        case 'SierMailGroup':
        case 'SierMailGroupAddEdit':
        case 'GoToMainPage':
        case 'Inventory':
        case 'InterfaceStatistics':
        case 'LboStatistics':
        case 'FirmupDetail':
        case 'FirmupTaskList':
        case 'FirmupTaskRsv':
        case 'FirmupTask':
        case 'ConfigTemplateManager':
        case 'ConfigTaskRsv':
        case 'ExecCli':
        case 'LboIndex':
        case 'LboProfileManager':
        case 'LboPolicyManager':
        case 'DdnsIndex':
        case 'TenantPassword':
        case 'DdnsPassword':
        case 'TenantUserTimeout':
        case 'IpPolicy':
        case 'ResetPassword':
        case 'TenantMonitor':
        case 'TenantMailGroup':
        case 'TenantMailGroupAddEdit':
          return true
      }
      return false
    // operator
    case 2:
      switch (to) {
        case 'TenantList':
        case 'SierOperationList':
        case 'SierMailGroup':
        case 'SierMailGroupAddEdit':
        case 'GoToMainPage':
        case 'Inventory':
        case 'InterfaceStatistics':
        case 'LboStatistics':
        case 'FirmupDetail':
        case 'FirmupTaskList':
        case 'FirmupTaskRsv':
        case 'FirmupTask':
        case 'ConfigTemplateManager':
        case 'ExecCli':
        case 'LboIndex':
        case 'LboProfileManager':
        case 'LboPolicyManager':
        case 'DdnsIndex':
        case 'DdnsPassword':
        case 'ResetPassword':
        case 'TenantMonitor':
        case 'TenantMailGroup':
        case 'TenantMailGroupAddEdit':
          return true
      }
      return false
  }
  return false
}

router.beforeEach((to, from, next) => {
  if (store.state.loggedIn === false) {
    switch (to.name) {
      case 'Login':
        next()
        break
      case 'ROOTPATH':
        next({ path: '/login' })
        break
      case '404':
        next({ path: '/login' })
        break
      case 'ResetPassword':
        next()
        break
      default:
        next({
          path: '/login',
          query: { nextpath: to.fullPath },
        })
        break
    }
  } else {
    switch (store.state.client.type) {
      case 'TENANT':
        switch (store.state.tenant.serviceLevel) {
          case 'ZTP':
            if (validateRouteForZtpOnly(to.name)) {
              next({ path: '/management/device-list' })
            } else {
              next()
            }
            break
          default:
            switch (store.state.priviledge) {
              case 1:
                if (validateRouteForGuest(to.name)) {
                  next({ path: '/management/device-list' })
                } else {
                  next()
                }
                break
              case 2:
                if (validateRouteForOperator(to.name)) {
                  next({ path: '/management/device-list' })
                } else {
                  next()
                }
                break
            }
            break
        }
        break
      case 'SIER':
        if (validateRouteForSier(to.name)) {
          next({ path: '/tenant-list' })
        } else {
          next()
        }
        break
      default:
        if (to.name !== 'Login') {
          next({ path: '/login' })
        } else {
          next()
        }
        break
    }
  }
})

export default router
