import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
    drawer: null,
    loggedIn: false,
    priviledge: 0,
    token: '',
    uid: '',
    client: {
      id: '',
      name: '',
      type: '',
    },
    tenant: {
      id: '',
      name: '',
      serviceLevel: '',
      mngdDeviceNum: '',
    },
    group: {
      all: [],
      selected: [],
    },
  },
  getters: {
    getTenantId: state => state.tenant.id,
  },
  mutations: {
    SET_DRAWER (state, payload) {
      state.drawer = payload
    },
    SET_USER_INFO (state, payload) {
      state.loggedIn = payload.loggedIn
      state.uid = payload.uid
      state.priviledge = payload.priviledge
      state.token = payload.token
      // state.refreshToken = payload.refreshToken
    },
    SET_CLIENT_INFO (state, payload) {
      state.client.type = payload.clientType
      state.client.id = payload.clientId
      state.client.name = payload.clientName
    },
    SET_TENANT_INFO (state, payload) {
      state.tenant.id = payload.tenantId
      state.tenant.name = payload.tenantName
      state.tenant.serviceLevel = payload.serviceLevel
      state.tenant.mngdDeviceNum = payload.mngdDeviceNum
      state.tenant.ztpDeviceNum = payload.ztpDeviceNum
      state.tenant.unuseZtpPass = payload.unuseZtpPass
    },
    SET_GROUP_ALL (state, payload) {
      state.group.all = payload
    },
    SET_GROUP_SELECTED (state, payload) {
      state.group.selected = payload
    },
  },
  actions: {
  },
  plugins: [createPersistedState({
    key: 'frakunet',
    paths: [
      'loggedIn',
      'priviledge',
      'token',
      // 'refreshToken',
      'uid',
      'client',
      'tenant',
      'group',
    ],
    storage: window.sessionStorage,
  })],
})
