// =========================================================
// * Vuetify Material Dashboard - v2.1.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vuetify-material-dashboard
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/base'
import './plugins/chartist'
import './plugins/vee-validate'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import axios from 'axios'

// css setup
// import '@fortawesome/fontawesome-free/css/all.css'
// import '@fortawesome/fontawesome-free/js/all.js'
import '@mdi/font/css/materialdesignicons.min.css'
import 'jsondiffpatch/dist/formatters-styles/annotated.css'
import 'jsondiffpatch/dist/formatters-styles/html.css'

// plugin setup
import TreeView from 'vue-json-tree-view'
Vue.use(TreeView)

Vue.config.productionTip = false

new Vue({
  router,
  store,
    created () {
      axios.interceptors.request.use(function (config) {
        if (store.state.token) {
          config.headers = {
            Authorization: `Bearer ${store.state.token}`,
          }
        }
        return config
      })
      axios.interceptors.response.use(function (response) {
        return response
      }, function (error) {
        if (error.message && error.message.indexOf('401') > 0) {
          /* Delete session storage after explicitly setting the login state to False. */
          store.state.loggedIn = false
          sessionStorage.clear()
          const jobResult = {
            status: 'warning',
            msg: '一定時間経過したため、オートログアウトしました。',
          }
          router.push({ name: 'Login', params: { inheritedJobResult: jobResult } })
        }
        return Promise.reject(error)
      })
    },
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app')
